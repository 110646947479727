




























































import mime from 'mime-types';
import { Ref, defineComponent, onMounted, reactive, ref } from '@/plugins/composition';
import { Resource } from '@/models';
import { axios } from '@/plugins/axios';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router';
import { useStorage } from '@vueuse/core';

export default defineComponent({
  name: 'ViewResourcePage',
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const resource = ref<Resource | null>(null);
    const authed = ref(false);
    const valid = ref(false);
    const email = useStorage<string | null>('resource-auth', null) as Ref<string | null>;
    const firstName = useStorage<string | null>('resource-auth-first-name', null) as Ref<string | null>;
    const lastName = useStorage<string | null>('resource-auth-last-name', null) as Ref<string | null>;
    const lastAuth = useStorage<number | null>('resource-auth-last', null) as Ref<number | null>;

    const { route } = useRouter();

    const formData = reactive({
      firstName: '',
      lastName: '',
      email: '',
      client: '',
    });

    onMounted(async () => {
      await fetchResource();
      formData.client = route.value.query.client as string;
      if (lastAuth.value && new Date().getTime() - lastAuth.value < 36000000) {
        if (email.value && firstName.value && lastName.value) {
          formData.email = email.value;
          formData.firstName = firstName.value;
          formData.lastName = lastName.value;
          await subnmit();
        }
      }
    });

    const fetchResource = async () => {
      resource.value = await Resource.find(props.uuid);
    };

    const subnmit = async () => {
      const response = await resource.value?.view(formData);
      email.value = formData.email;
      lastAuth.value = new Date().getTime();
      firstName.value = formData.firstName;
      lastName.value = formData.lastName;

      if (response) {
        authed.value = true;
      }
    };

    const download = async () => {
      if (!resource.value) {
        return;
      }
      const file = await axios.get(resource.value.directSlug() + 'download', { responseType: 'blob' });

      const blob = new Blob([file.data], { type: mime.lookup(resource.value.data.filePath as string) as string });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = resource.value.data.name;
      link.click();
      URL.revokeObjectURL(link.href);
    };

    return {
      resource,
      authed,
      formData,
      subnmit,
      valid,
      download,
      routeNames,
    };
  },
});
